import {TradingType} from './model'
import {UserInfo} from 'Types/login'

export enum EXECUTION_TYPE {
    SMALLCASE = 'SCG',
    MANUAL = 'MANUAL',
    AUTO = 'AUTO'
}

export enum ExecutionType {
    TRACKING = 'TRACKING',
    TRADING = 'TRADING'
}

export enum USER_PREFERENCE_TYPE {
    CUSTOM = 'CUSTOM',
    RECOMMENDED = 'RECOMMENDED'
}

export enum SUBSCRIPTION_MODE {
    FREE = 'FREE'
}

export interface UpdateSubscriptionRequest {
    modelId: string
}

export interface SubscriptionRequest {
    brokerId?: string
    byQuantity?: number
    byAbsMaxLoss?: number
    byAbsMaxCapital: number
    byMaxLots?: number
    executionType?: ExecutionType
    modelId: string
    preferenceType?: USER_PREFERENCE_TYPE
}

export interface SubscriptionResponse {
    id: number
    modelId: number
    status: boolean
    subscriptionMode: SUBSCRIPTION_MODE
    userId: number
    validity: number
}

export interface WizardStepProps {
    prismicModelData?: any
    userAllocatedCapital?: number
    setUserAllocatedCapital?: Function
    userAllocatedLots?: number
    setUserAllocatedLots?: Function
    preferredExecution?: string
    setPreferredExecution?: Function
    allBrokers?: any
    drawDown?: number
    refetchUserModelSubscriptionCheck?: Function
    showEnableNotificationCard?: boolean
    openAppSettings?: Function
    containerStyle?: object
    easyTradeEnabled?: boolean
    easyTradeType?: TradingType
    isEasyTradeFlow?: boolean
    userInfo?: UserInfo
    isDerivatives?: boolean
    minCapitalRequired?: number
}

export interface IExecution {
    title: string
    description: string
    type: EXECUTION_TYPE
    features: string[]
}

export enum ModelAccessMode {
    ACTIVE = 'ACTIVE',
    REQUESTED = 'REQUESTED',
    INACTIVE = 'INACTIVE'
}

export interface ModelSubscriptionResponse {
    byAbsMaxCapital: number
    executionType: ExecutionType
    status: boolean
    accessStatus?: ModelAccessMode
    freeTrailDatetime?: Date
    accessValidTill?: Date
    allowExtendSubscription?: boolean
    isRenewalEligible?: boolean
}

export interface PaymentLinkResponse {
    amount: number
    description: string
    expiresAt?: number
    paymentLink: string
}

export interface PaymentFormUrl {
    url: string
}

export interface PaymentStatusResp {
    status: string
}
