import { GetServerSidePropsContext, NextPageContext } from 'next';

import { getCookie } from './cookie';

export const getAxiosConfigFromNext = (ctx?: NextPageContext | GetServerSidePropsContext, forceUseTestUser = false) => {
    let token = getCookie('authToken', { req: ctx?.req, res: ctx?.res });

    if (!token && forceUseTestUser) {
        token =
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJkZGQ2ZDRkOS03N2FkLTRhNGYtYTY1NS1mMTEwOTIzOWUxNzkiLCJpYXQiOjE2ODEzODEzOTMsImV4cCI6MTY4NjU2NTM5M30.4zjb4V4bfU-z_rLDrfv-AMybcglpxg_qI_jEq3yybz8';
    }
    const config = {
        headers: { Authorization: `Bearer ${token}` },
    };

    return config;
};

export const toFixed = (n: number | string, digits: number = 2): string => {
    const fallback = 0;
    if (!n) return fallback.toFixed(digits);
    if (typeof n === 'string') return Number(n).toFixed(digits);
    return n.toFixed(digits);
};

export function autoReadSMS(cb: Function) {
    // used AbortController with setTimeout so that WebOTP API (Autoread sms) will get disabled after 1min
    const signal = new AbortController();
    setTimeout(() => {
        signal.abort();
    }, 1 * 60 * 1000);
    async function main() {
        if ('OTPCredential' in window) {
            try {
                if (navigator.credentials) {
                    try {
                        await navigator.credentials
                            // @ts-ignore
                            .get({ abort: signal, otp: { transport: ['sms'] } })
                            .then((content) => {
                                // @ts-ignore
                                if (content && content.code) {
                                    // @ts-ignore
                                    cb(content.code);
                                }
                            })
                            .catch((e) => {
                                alert(e);
                            });
                    } catch (e) {
                        alert(e);
                        return;
                    }
                }
            } catch (err) {
                alert(err);
                console.log(err);
            }
        }
    }
    main();
}

export const isTrue = (value: string | boolean) => value === 'true' || value === true;

export const isValidPhoneNumber = (number: string) => {
    const regex = /^[0-9]{10}$/;
    return !regex.test(number);
};
