import { useUserInfo } from '@/services/dataStore/reactQuery/user';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import type { AppContext, AppProps } from 'next/app';
import App from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import Script from 'next/script';
import NextNProgress from 'nextjs-progressbar';
import React, { useEffect, useState } from 'react';

import { fetchModelDataFromPrismic, getAllModels } from '@/services/api/modelsPage';

import { LoadingScreen } from '@/ui/container/common/loadingScreen';

import { getCookie } from '@/utils/cookie';
import { HistoryProvider } from '@/utils/hooks/routing';
import { getAxiosConfigFromNext } from '@/utils/others';
import { gaScriptUtils } from '@/utils/scriptUtils';
import { Tracking } from '@/utils/trackingUtils';

import '@/styles/globals.css';
import { Context } from '@ui-library/contexts/remoteConfig/context';
import { fetchAccessTokenAndSetHeaders } from 'Utils/fetchUtils';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false, // default: true
            refetchInterval: 6000000, // refetch time is very long
        },
    },
});

const useCheckPageRedirection = () => {
    const router = useRouter();
    const authToken = getCookie('authToken');
    const { pathname, asPath } = router;

    const [loadable, setLoadable] = useState(false);

    const isPubliclyAvailableLink = [
        '/',
        '/c_p',
        '/green',
        '/green/pricing',
        '/qr',
        '/login',
        '/terms-conditions',
        '/privacy-policy',
        '/market-feed',
        '/market-feed/[id]',
        '/green/code/[code]',
        '/green/codes',
        '/chat',
        '/share/[uuid]/[id]',
    ].includes(pathname);
    const isHomepage = ['/'].includes(pathname);
    useEffect(() => {
        const isLoginPage = pathname === '/login';

        if (!authToken && !isPubliclyAvailableLink) {
            // redirect if url is not /
            if (pathname !== '/') {
                router.push(`/login?redirect=${asPath}`);
            }
        }

        if (!!authToken && isLoginPage && !isHomepage) {
            router.push(`/dashboard`);
        }
        setLoadable(true);
    }, []);

    return { loadable, isPubliclyAvailableLink };
};

export const Wrapper = () => {
    const { userInfo } = useUserInfo();

    useEffect(() => {
        const userId = userInfo?.uuid;
        if (!!userId) {
            Tracking.identifyUser(userId);
        }
    }, [userInfo]);
    return null;
};

export default function NextApp({ Component, pageProps, props }: AppProps) {
    const { loadable, isPubliclyAvailableLink } = useCheckPageRedirection();

    useEffect(() => {
        Tracking.initMixpanel();
    }, []);

    if (!loadable && !isPubliclyAvailableLink) return <LoadingScreen />;
    fetchAccessTokenAndSetHeaders();

    const isPrimaryDomain = props.global.host === 'www.investmint.club';

    return (
        <React.Fragment>
            <Head>
                <script dangerouslySetInnerHTML={{ __html: gaScriptUtils }} />
            </Head>
            <style jsx global>{`
                html,
                body {
                    font-family: 'Plus Jakarta Sans', serif;
                }
            `}</style>

            <NextNProgress color="#5667FF" />

            <QueryClientProvider client={queryClient}>
                {/*// @ts-ignore*/}
                <Context.Provider value={{}}>
                    <HistoryProvider>
                        <>
                            <Wrapper />
                            <Component {...pageProps} />
                        </>
                    </HistoryProvider>
                </Context.Provider>
            </QueryClientProvider>
        </React.Fragment>
    );
}

NextApp.getInitialProps = async (nextCTX: AppContext) => {
    const pageProps = await App.getInitialProps(nextCTX);
    if (nextCTX.ctx !== undefined && nextCTX.ctx.res !== undefined) {
        nextCTX?.ctx?.res.setHeader('Cache-Control', 'public, s-maxage=1800, stale-while-revalidate=18000');
    }
    //@ts-ignore
    const config = getAxiosConfigFromNext(nextCTX);
    const systemModels = await getAllModels(config);
    const modelUUIDs = systemModels.map((model: any) => model.uuid);
    const prismicModels = await fetchModelDataFromPrismic(modelUUIDs);

    const host = nextCTX.ctx.req?.headers.host;

    return {
        props: {
            global: {
                host,
                systemModels,
                prismicModels,
            },
            ...pageProps,
        },
    };
};
