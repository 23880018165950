import { useQuery } from '@tanstack/react-query';

import { getModelPositions } from '@/services/api/modelsPage';
import { getCurrentUserInfo, getModelUserSubscription, getUserActions, getUserOpenPositions, getUserSubscription } from '@/services/api/user';

export const useUserSubscriptions = () => {
    const { data: userSubscription, isLoading } = useQuery({
        queryKey: ['userSubscription'],
        queryFn: () => getUserSubscription(),
    });

    return { userSubscription, isLoading };
};

export const useAllUserActions = () => {
    const { data: userAction, isLoading } = useQuery({
        queryKey: ['userAction'],
        queryFn: () => getUserActions(),
    });

    return { userAction, isLoading };
};

export const useUserOpenPosition = () => {
    const { data: userOpenPosition, isLoading } = useQuery({
        queryKey: ['getUserOpenPosition'],
        queryFn: () => getUserOpenPositions(),
    });

    return { userOpenPosition: userOpenPosition, isLoading };
};

export const useModelUserSubscription = (uuid: string) => {
    const { data: modelUserSubscription, isLoading } = useQuery({
        queryKey: ['modelUserSubscription'],
        queryFn: () => getModelUserSubscription(uuid),
    });

    return { modelUserSubscription, isLoading };
};

export const useModelOpenPositions = (uniqueKey: string) => {
    const { data: modelPositions, isLoading } = useQuery({
        queryKey: ['modelOpenPositions'],
        queryFn: () => getModelPositions(uniqueKey),
    });

    return { modelPositions, isLoading };
};

export const useUserInfo = () => {
    const {
        data: userInfo,
        isLoading,
        refetch,
    } = useQuery({
        queryKey: ['userInfo'],
        queryFn: () => {
            return getCurrentUserInfo().catch((e) => {
                return {};
            });
        },
        retry: 0,
        retryDelay: 500000,
    });


    return { userInfo, isLoading,refetch };
};
