/******************************
 Colors
 *******************************/

const colors = {
    BASE: {
        PRIMARY: '#292B32',
        SECONDARY: '#5F6B89',
        TERTIARY: '#E8EBF3',
        LIGHT: '#5b758d33',
        SUPER_LIGHT: '#F7F8FC',
        LIGHTEST: '#A9A9A9'
    },
    BRAND: {
        WHITE: '#fff',
        GREEN: '#1BB473',
        RED: '#DC3D3D',
        BLUE: '#5667FF',
        BLACK: '#000',
        YELLOW: '#F0C379',
        BROWN: '#956507',
        GOLDEN: '#d79e0c',
        DARK_GREEN: '#0F394A',
        AQUA: '#ABDAED',
        PURPLE: '#5B0A58',
        DARK_BLUE: '#121685b0',
        DARKEST_BLUE: '#0A2A5B',
        WINE: '#C63535',
        COIN: '#FFC246',
        TEAL: '#2990B0',
        PALE: '#FEEFAB',
        BEIGE: '#DCCF8D',
        ORANGE: '#FF9600',
        PALE_YELLOW: '#FFF2CE'
    },
    BRAND_LIGHT: {
        RED: '#FFE1E1',
        BLUE: '#E3E6FF',
        YELLOW: '#F6ED98',
        GREEN: '#E9F5F0',
        PINK: '#FBF1FF',
        GOLDEN: '#FFE9C9',
        AQUA: '#C0E0EE',
        DARK_BLUE: '#707EFF',
        WINE: '#ff6cc43b',
        COIN: '#EE9B49',
        ORANGE: '#fbebcf',
        PEACH: '#FFEEEE',
        BROWN: '#FFF0D9',
        TEAL: '#DCF0FF'
    },
    MODEL_ICON_BACKGROUND: '#fbebcf',
    HIGHLIGHT: '#FFECBC',
    SEPARATOR: '#E8EBF3',
    SEPARATOR_LIGHTEST: '#F0F0F0',
    WARNING: '#DB773D',
    WARNING_BACKGROUND: '#FFF7E0',
    PILLARS: {
        PURPLE: {
            PRIMARY: '#9031B1',
            SECONDARY: '#E6C7F0',
            LIGHT: '#F9ECFD',
            SUPER_LIGHT: '#FBF2FE',
            ICON: '#EBB2FF'
        },
        BROWN: {
            PRIMARY: '#9A590F',
            SECONDARY: '#FBE7D0',
            LIGHT: '#FFF6EC',
            SUPER_LIGHT: '#FFF6EC75',
            ICON: '#FDA33E'
        },
        BLUE: {
            PRIMARY: '#2990B0',
            SECONDARY: '#B1E2F1',
            LIGHT: '#E8F7FC',
            SUPER_LIGHT: '#EBF5F8',
            ICON: '#53AED2'
        },
        GREEN: {
            PRIMARY: '#5B8B02',
            SECONDARY: '#D9F0C7',
            LIGHT: '#F3FDEC',
            SUPER_LIGHT: '#F7FEF2',
            ICON: '#BDDF81'
        }
    },
    GOLDEN_COIN: '#D79E0C',
    TEMP_PINK: '#f9edfe',
    PROFILE: {
        PRIMARY: '#FEE17C',
        SECONDARY: '#FBF2FE'
    },
    TELEGRAM: {
        PRIMARY: '#144C6B',
        SECONDARY: '#C8DFE8',
        LIGHT: '#e2eef7'
    },
    MODELS_BACKGROUND: {
        monthly: '#F5F3FF',
        daily: '#ECFDFF',
        'bi-weekly': '#F0FDF9',
        weekly: '#F0FDF9',
        fno: '#FEFBE8'
    },
    MODELS_PAGE_HEADER_BACKGROUND: {
        monthly: '#E7E3FF',
        daily: '#D6EFF7',
        'bi-weekly': '#CCFBEF',
        weekly: '#CCFBEF',
        fno: '#FEF7C3'
    },
    MODELS_TAGS_ICON_BACKGROUND: {
        monthly: '#A48AFB',
        daily: '#ABDAED',
        'bi-weekly': '#99F6E0',
        weekly: '#99F6E0',
        fno: '#FEEE95'
    },
    WHATSAPP: '#25D366',
    BRAND_HIGHLIGHTER: '#f4f5ff',
    CARD_BACKGROUND: '#D7DAF3',
    OFFERING_BACKGROUND: '#EEEFFF',
    OFFERING_ICON: '#d8dbff',
    PORTFOLIO_CARD_BACKGROUND: '#F5FBFF',
    NEW_BASE: {
        PRIMARY: '#292B32',
        SECONDARY: '#5F6B89',
        TERTIARY: '#E8EBF3',
        WHITE: '#FFFFFF',
        BRAND: '#5667FF',
        BRAND_LIGHT: '#E3E6FF',
        GREEN: '#1BB473',
        GREEN_LIGHT: '#E9F5F0',
        RED: '#DC3D3D',
        RED_LIGHT: '#FFE1E1'
    },
    OTHERS: {
        NOTICE_BOARD: {
            PRIMARY: '#C0F2FF',
            SECONDARY: '#EBFBFF'
        },
        ACTIONABLE_BANNER: {
            ICON: '#C2C9FF'
        },
        BOTTOM_TABS: '#5F6B89',
        APP_OFFERINGS: {
            BROKER: {
                iconColor: '#6927DA',
                backgroundColor: '#F5F3FF'
            },
            QUANT: {
                iconColor: '#0E7090',
                backgroundColor: '#ECFDFF'
            },
            SPOT: {
                iconColor: '#9F1AB1',
                backgroundColor: '#FDF4FF'
            },
            REAL_TIME_NEWS: {
                iconColor: '#A15C07',
                backgroundColor: '#FEFBE8'
            }
        }
    }
}

export default colors
