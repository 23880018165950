import axios, { AxiosResponse } from 'axios';

import { getAxiosConfigFromNext } from '@/utils/others';
import { getBackendURL } from '@/utils/urlUtils';

export const getCurrentUserInfo = () => {
    const config = getAxiosConfigFromNext();
    return axios.get(`${getBackendURL(false)}/api/v1/user/me`, config).then((res) => res.data);
};

export const getUserOpenPositions = () => {
    const config = getAxiosConfigFromNext();
    return axios.get(`${getBackendURL(false)}/api/v1/user-signals/open-positions`, config).then((res) => res.data);
};

export const getUserSubscription = () => {
    const config = getAxiosConfigFromNext();
    return axios.get(`${getBackendURL(false)}/api/v1/user-subscriptions`, config).then((res) => res.data);
};

export const getUserActions = (config?: any) => {
    const finalConfig = config || getAxiosConfigFromNext();
    return axios.get(`${getBackendURL(false)}/api/v1/user-action/pending?recents=true&limit=200`, finalConfig).then((res) => res.data);
};

export const getModelUserSubscription = (uuid: string) => {
    const config = getAxiosConfigFromNext();
    return axios.get(`${getBackendURL(false)}/api/v1/user-subscriptions/${uuid}`, config).then((res) => res.data);
};

export const removeUserModelSubscription = async (subscription: any) => {
    const config = getAxiosConfigFromNext();
    const url = `${getBackendURL(false)}/api/v1/user-subscriptions`;
    const { data } = await axios.put<AxiosResponse<any>>(url, subscription, config);
    return data;
};

export const createTempUser = async () => {
    const { data } = await axios.get<any>(`${process.env.NEXT_PUBLIC_API_URL}/api/v1/user/temp/create`);
    const backendId = data?.user?.uuid;
    const backendAuthToken = data?.authToken;
    return {
        backendId,
        backendAuthToken,
    };
};

export const getMintBuzzGreenInfo = async () => {
    const config = getAxiosConfigFromNext();
    return axios
        .get(`${process.env.NEXT_PUBLIC_API_URL}/api/v1/user-broker/swastika/membership`, {
            ...config,
            headers: {
                Authorization: `${config.headers.Authorization}`,
            },
        })
        .then((res) => res.data) as any as TMIntBuzzInfo;
};

export const generateInviteCode = async ({ designation, testimonial }) => {
    const config = getAxiosConfigFromNext();
    return axios
        .post(
            `${process.env.NEXT_PUBLIC_API_URL}/api/v1/user-broker/swastika/testimonial`,
            {
                intent: 'OPEN_GREEN_ACCOUNT',
                comment: {
                    testimonial: testimonial,
                    designation,
                },
            },
            {
                ...config,
                headers: {
                    Authorization: `${config.headers.Authorization}`,
                },
            },
        )
        .then((res) => res.data) as any;
};

export const claimInviteCode = async (inviteId: string) => {
    const config = getAxiosConfigFromNext();

    console.log('inviteId', inviteId);
    return axios
        .post(
            `${process.env.NEXT_PUBLIC_API_URL}/api/v1/user-broker/swastika/invites/claim`,
            {
                inviteId: inviteId,
            },
            {
                ...config,
                headers: {
                    Authorization: `${config.headers.Authorization}`,
                },
            },
        )
        .then((res) => res.data) as any;
};

type TInviteCode = {
    inviteId: number;
    user: {
        name: string;
        email: string;
        emailHash: string;
    };
    inviteCode: string;
    intent: string;
    comment: {
        imgUrl: string;
        testimonial: string;
        designation?: string;
    };
};

type TCodeDataAPI = {
    totalCount: number;
    totalPages: number;
    currentPage: number;
    invites: TInviteCode[];
};
export const getPublicInviteCodes = async () => {
    const config = getAxiosConfigFromNext();
    return axios
        .get(`${process.env.NEXT_PUBLIC_API_URL}/api/v1/user-broker/swastika/invites`, {
            ...config,
            headers: {
                Authorization: `${config.headers.Authorization}`,
            },
        })
        .then((res) => res.data) as any as TCodeDataAPI;
};

export const getUserInviteCOde = async () => {
    const config = getAxiosConfigFromNext();
    return axios
        .get(`${process.env.NEXT_PUBLIC_API_URL}/api/v1/user-broker/swastika/invites/me`, {
            ...config,
            headers: {
                Authorization: `${config.headers.Authorization}`,
            },
        })
        .then((res) => res.data) as any as TInviteCode;
};

export const getInviteInfoFromCode = async (code) => {
    const config = getAxiosConfigFromNext();
    return axios
        .get(`${process.env.NEXT_PUBLIC_API_URL}/api/v1/user-broker/swastika/invites/code/${code}`, {
            ...config,
            headers: {
                Authorization: `${config.headers.Authorization}`,
            },
        })
        .then((res) => res.data) as any as TCodeDataAPI;
};

export const postTestimonial = async (data: { testimonial: string; designations: string }) => {
    const config = getAxiosConfigFromNext();
    return axios
        .post(`${process.env.NEXT_PUBLIC_API_URL}/api/v1/user-broker/swastika/invites`, data, {
            ...config,
            headers: {
                Authorization: `${config.headers.Authorization}`,
            },
        })
        .then((res) => res.data) as any as TCodeDataAPI;
};

export const getCodeHolderDetails = async (code: string) => {
    const config = getAxiosConfigFromNext();
    if (!code) return;
    return axios
        .get(`${process.env.NEXT_PUBLIC_API_URL}/api/v1/user-broker/swastika/code/${code}`, {
            ...config,
            headers: {
                Authorization: `${config.headers.Authorization}`,
            },
        })
        .then((res) => res.data) as any as {
        userName: string;
        code: string;
    };
};

export const setRegistrationCode = ({ registrationCode }) => {
    if (!registrationCode) return;
    const config = getAxiosConfigFromNext();

    return axios
        .post(
            `${process.env.NEXT_PUBLIC_API_URL}/api/v1/user-broker/verify-registration-code`,
            {
                registrationCode,
            },
            {
                ...config,
                headers: {
                    Authorization: `${config.headers.Authorization}`,
                },
            },
        )
        .then((res) => res.data) as any as TMIntBuzzInfo;
};

export type TMIntBuzzInfo = {
    currentUserState: {
        status:
            | 'ELIGIBLE'
            | 'NOT_ELIGIBLE'
            | 'JOINED_WAITLIST'
            | 'ELIGIBLE'
            | 'PAYMENT_INIT'
            | 'PAYMENT_FAILED'
            | 'PAYMENT_SUCCESS'
            | 'KYC_OTP_PENDING'
            | 'KYC_OTP_VERIFIED'
            | 'COMPLETED';
        statusData: Record<string, any>;
    };
    brokerDetails: any;
    unifiedPlans: any;
};

export const joinWaitlistAPI = () => {
    const config = getAxiosConfigFromNext();
    return axios.post(`${getBackendURL(false)}/api/v1/user-broker/swastika/join-waitlist`, {}, config).then((res) => res.data);
};

export const getPaymentLinkAPI = ({ amount = 500, id = '', brokerName, unifiedPlanId }) => {
    const config = getAxiosConfigFromNext();
    return axios
        .post(
            `${getBackendURL(false)}/api/v1/user-broker/registration-init?markCompleted=true`,
            {
                unifiedPlanId,
                paymentDetails: {
                    intent: 'OPEN_GREEN_ACCOUNT',
                    intentDetails: {
                        brokerName: brokerName,
                        registrationPlanId: id,
                        amount: amount,
                    },
                },
            },
            config,
        )
        .then((res) => res.data);
};
