export type MintCoinAwardList = Record<MintCoinPurpose, Record<string, any>>

export enum TransactionType {
    DEBIT = 'DEBIT',
    CREDIT = 'CREDIT'
}

export interface UserMintCoinBalance {
    balance: number
    totalCredited: number
    totalCredit?: number
    nonRedeemableMintCoinsBalance?: number
}

export interface InvitedUser {
    uuid: string
    name: string
    phone: number
}

export enum MintCoinPurpose {
    /* Debit */
    MEMBERSHIP_REBALANCE = 'MEMBERSHIP_REBALANCE',
    MEMBERSHIP_PURCHASE = 'MEMBERSHIP_PURCHASE',

    /* Credit */
    USER_SIGNUP = 'USER_SIGNUP',
    ASK_AQ_REWARD = 'ASK_AQ_REWARD', // progressive profile question reward

    COMPLETE_PROFILE = 'COMPLETE_PROFILE',
    USER_FIRST_TIME_INITIATE_POSITION = 'USER_FIRST_TIME_INITIATE_POSITION',
    USER_FIRST_TIME_POSITION_CLOSE = 'USER_FIRST_TIME_POSITION_CLOSE',
    USER_FIRST_TIME_PORTFOLIO_SYNC = 'USER_FIRST_TIME_PORTFOLIO_SYNC',

    USER_NORMAL_POSITION_INITIATE = 'USER_NORMAL_POSITION_INITIATE',
    USER_NORMAL_POSITION_CLOSE = 'USER_NORMAL_POSITION_CLOSE',

    USER_POSITION_INITIATE_AFTER_2_WEEKS = 'USER_POSITION_INITIATE_AFTER_2_WEEKS',
    USER_POSITION_CLOSE_AFTER_2_WEEKS = 'USER_POSITION_CLOSE_AFTER_2_WEEKS',

    REFERRED_USER_TRADED = 'REFERRED_USER_TRADED',
    USER_FIRST_TIME_QUANT_TRADE = 'USER_FIRST_TIME_QUANT_TRADE',
    USER_SECOND_TIME_QUANT_TRADE = 'USER_SECOND_TIME_QUANT_TRADE',
    USER_FIRST_MINT_BUZZ_TRADE_COMPLETED = 'USER_FIRST_MINT_BUZZ_TRADE_COMPLETED',
    USER_SECOND_MINT_BUZZ_TRADE_COMPLETED = 'USER_SECOND_MINT_BUZZ_TRADE_COMPLETED',
    USER_NORMAL_QUANT_POSITION_INITIATE = 'USER_NORMAL_QUANT_POSITION_INITIATE',
    USER_NORMAL_QUANT_POSITION_CLOSE = 'USER_NORMAL_QUANT_POSITION_CLOSE',
    USER_NORMAL_MINT_BUZZ_POSITION_INITIATE = 'USER_NORMAL_MINT_BUZZ_POSITION_INITIATE',
    USER_NORMAL_MINT_BUZZ_POSITION_CLOSE = 'USER_NORMAL_MINT_BUZZ_POSITION_CLOSE'
}

export interface MintCoinLedger {
    transactionType: TransactionType
    units: number
    purpose: MintCoinPurpose
    comments: any
    createdAt: Date
    userInvited: InvitedUser
}

export interface MintCoinTransactions {
    data: MintCoinLedger[]
    limit: number
    offset: number
    total: number
}

export interface UserMintCoinEarning {
    summary: UserMintCoinBalance
    transactions: MintCoinTransactions
}

export enum MintCoinRewardStatus {
    REWARDED = 'REWARDED',
    NOT_REWARDED = 'NOT_REWARDED'
}

export interface MintCoinRewardInfo {
    status: MintCoinRewardStatus
    reason: string
    rewardedValue?: number
    bonusValue?: number
}

export interface OrderStatusInfo {
    isSmallcaseExecutionFlow: boolean
    transactionId: string
    tagId: string
    userBrokerId: string
}
