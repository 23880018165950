export interface PaginationConfig {
    limit?: number
    offset?: number
    fromDate?: string
    toDate?: Date
}

export enum APIDataType {
    USER = 'user',
    SYSTEM = 'system'
}

export interface IBaseUrls {
    GET_API_URL: string
    NON_GET_API_URL: string
    MINT_BUZZ_CLOUDFLARE_API_URL: string
}
export interface IConfig {
    getAPIHostUrl: () => IBaseUrls
    changeEnv: (env?: API_CONFIG) => void
    currentEnv: API_CONFIG
    defaultEnv: API_CONFIG
    baseUrls: Record<API_CONFIG, IBaseUrls>
    setCustomEnvUrl: (url: string) => void
}

export const enum API_CONFIG {
    STAGING = 'STAGING',
    PRODUCTION = 'PRODUCTION',
    CUSTOM = 'CUSTOM'
}
