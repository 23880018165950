/* Types */
import {Pillar} from 'Types/pillar'
import {UserActionExecutionType} from 'Types/actions'
import {epochNow} from '@suprsend/react-headless/dist/utils'

export interface ModelImages {
    image: string
}

export enum ModelEnums {
    BTST = 'btst',
    BACK_UP = 'backup',
    LONG_INDIA = 'long_india_nifty_bees',
    QUICK_SHORT = 'quick_short',
    HIGH_FIVE = 'high_five',
    NIGHT_CRAWLER = 'night_crawler',
    ALPHA_ALLY = 'alpha_ally',
    SECTOR_SUPERSTAR = 'sector_superstars',
    NIFTY_RIDER = 'nifty_rider',
    BHSH = 'bhsh',
    TRENDING_TEN = 'trending_ten'
}

export enum ModelStateType {
    PUBLISHED = 'PUBLISHED',
    UNPUBLISHED = 'UNPUBLISHED'
}

export enum TradingType {
    SMALLCASE_GATEWAY = 'SMALLCASE_GATEWAY',
    INVES_BROKER = 'INVESBROKER'
}
export interface Model {
    id: number
    uuid: string
    name: string
    uniqueKey: ModelEnums | null
    images: ModelImages
    tags: ModelTags[]
    easyTradeEnabled: boolean
    easyTradeType?: TradingType
    pillars: Pillar[]
    state: ModelStateType
    isDerivatives: boolean
    subscriberCount?: number
    modelStats?: Array<Record<string, string>>
    userActionExecutionType?: UserActionExecutionType
}

export interface ModelTags {
    id: number
    name: string
}

export enum MODEL_NAMES {
    NIFTY_RIDER = 'Nifty50 Rider',
    BANK_NIFTY_RIDER = 'BankNifty Rider',
    STARTS_ALIGNED = 'Stars Aligned',
    QUICK_SHORT = 'Quick Short!',
    BACK_UP = 'Back UP!'
}

export interface FetchModelInfo {
    uuid?: string
    selectedTimeFrame?: number | null
    performanceDataStartTime?: Date
    isEquityChart?: boolean
    isModelEnabledAsTool: boolean
    configurationId?: number | null
}

export interface Streak {
    duration: string
    returnsInPercent: [number]
}

export enum ModelCardLayout {
    FULL = 'Full Width',
    COMPACT = 'Compact'
}

export enum ModelCardType {
    RECENTLY_LAUNCHED = 'RECENTLY LAUNCHED',
    SUBSCRIBED = 'SUBSCRIBED',
    NON_SUBSCRIBED = 'NON SUBSCRIBED'
}

export interface ModelPerformanceAttributes {
    drawDownPoints: Record<string, number | string>
    absDrawDownInPercent?: number
    absReturnsInPercent?: number
    drawDownPercent: number
    modelMetrics: Record<string, number>
    cummulativePnlPercent: Record<string, number | string>
    annualReturnsInPercent: number
    estimatedReturns: Record<string, number | string>
    fromDate?: Date
    toDate?: Date
    graphType: ChartType
    totalNumOfActions?: number
    totalNumOfTrades?: number
    backtestDataPeriodInYears?: number
}

export interface ModelPerformance {
    system: ModelPerformanceAttributes
    user?: ModelPerformanceAttributes
}

export interface StreakInfo {
    system: Streak
    user?: Streak
}

export enum ChartType {
    BAR = 'BAR',
    LINE = 'LINE'
}

export interface ModelPreference {
    modelId?: string
    capital: number
    lots?: number
    isPreferredCapitalSet?: boolean
}

export enum ModelConfigType {
    ENUM = 'enum',
    RANGE = 'range',
    MULTI_SELECTION = 'multi_selection'
}

export interface ModelConfiguration {
    id: number
    name: string
    type: ModelConfigType
    valueType?: string
    label: string
    isNested?: boolean
    default?: string | number
    values: any
    status: string
    description?: string
    amo?: string
}

export enum ConfigType {
    DEFAULT = 'DEFAULT',
    PRIMARY = 'PRIMARY',
    UPCOMING = 'UPCOMING'
}

export interface ModelConfigReqObj {
    modelKey: string
    config: Record<string, any>
    usage: ConfigType
    isDefault: boolean
}

export enum FormStatus {
    FIXED = 'FIXED',
    ENABLED = 'ENABLED'
}
