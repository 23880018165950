import axios from 'axios';

import { getAxiosConfigFromNext } from '@/utils/others';
import { getBackendURL } from '@/utils/urlUtils';

import { PRISMIC_DOCUMENT_TYPE, PRISMIC_REPO_NAME } from '@/constants/prismic';
import { createClient } from '@prismicio/client';
import qs from 'qs';

export const getModelPerformanceByFilter = (uuid: string, currentConfigId, year = null, lastYear = null) => {
    const config = getAxiosConfigFromNext();
    const currentYear = new Date().getFullYear();
    const yearString = year !== 'YTD' ? `from_date=${year}-01-01&to_date=${lastYear || year}-12-31` : `from_date=${currentYear}-01-01`;
    const currentConfigString = currentConfigId ? '&configuration_id=' + currentConfigId : '';

    return axios.get(`${getBackendURL(true)}/api/v1/performance/backtest/model/${uuid}?${yearString}${currentConfigString}`, config).then((res) => res.data);
};

export const getModelAction = (modelKey: string) => {
    const config = getAxiosConfigFromNext();
    return axios.get(`${getBackendURL(false)}/api/v1/actions?model_key=${modelKey}`, config).then((res) => res.data);
};

export const getSysTrades = (modelKey: string, config?: any) => {
    return axios
        .get(`${process.env.NEXT_PUBLIC_API_CACHE_URL}/api/v1/sys-trades?shouldGroup=true&recent=true&model_id=${modelKey}&limit=3&offset=0`, config)
        .then((res) => res.data);
};

export const fetchTradeLogsByModelId = async (modelUuid: string, config?: any) => {
    const { limit, offset } = config;
    const url = `${process.env.NEXT_PUBLIC_API_CACHE_URL}/api/v1/sys-trades?shouldGroup=true&recent=true&model_id=${modelUuid}&${qs.stringify(config)}`;
    const { data } = await axios.get<any>(url);
    return { data: data.data, nextPage: offset + limit };
};

export const fetchRequestForm = () => {
    return `${process.env.NEXT_PUBLIC_API_CACHE_URL}/api/v1/user-request/access-form/`;
};

export const fetchLiveTradesByModelId = (modelUniqueKey: string) => {
    const config = getAxiosConfigFromNext();
    return axios.get(`${getBackendURL()}/api/v1/model/${modelUniqueKey}/open-positions?send_model_info=true`, config).then((res) => res.data);
};
export const getAllModels = (config: any) => {
    return axios.get(`${process.env.NEXT_PUBLIC_API_CACHE_URL}/api/v1/models`, config).then((res) => res.data);
};

export const fetchModelDataFromPrismic = async (uuids: string | string[]) => {
    const client = createClient(PRISMIC_REPO_NAME);
    const document = await client.getAllByUIDs(PRISMIC_DOCUMENT_TYPE.MODEL_FACT_SHEET, uuids as string[]);
    return Object.fromEntries(document.map(({ data }) => [data.modelName, data]));
};
// Note : Implement it for uuids too
export const getModelPerformance = (uuid: string, config?: any) => {
    return axios.get(`${getBackendURL()}/api/v1/performance/model/${uuid}`, config).then((res) => res.data);
};
export const getModelStreakInfo = (uuid: string) => {
    const config = getAxiosConfigFromNext();

    return axios.get(`${getBackendURL()}/api/v1/performance/model/${uuid}/streak`, config).then((res) => res.data);
};
export const getModelActions = (modelKey: string, config: any) => {
    return axios.get(`${getBackendURL(false)}/api/v1/actions?model_key=${modelKey}`, config).then((res) => res.data);
};
export const getModelPositions = (modelKey: string, config?: any) => {
    return axios.get(`${getBackendURL()}/api/v1/model/${modelKey}/open-positions?send_model_info=true`, config).then((res) => res.data);
};
