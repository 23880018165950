export const PRISMIC_REPO_NAME = 'investmint';

export const MODEL_REQUIRED_CONDITION_CONTENT = {
    WHEN_DOES_IT_WORK: 'When does it work',
    WHEN_DOES_IT_NOT_WORK: 'When does it not work?',
};

export const PRISMIC_DOCUMENT_TYPE = {
    MODEL_FACT_SHEET: 'model-information-page',
    MINT_LIBRARY_CARD: 'mint-library-card',
    PILLAR: 'pillar-page',
    STORY_GROUP: 'story-group',
    HOME_PAGE: 'home-page-information',
    PROMOTIONAL_CAROUSEL: 'promotionalcarousel',
    CUTS: 'cuts',
    PROMOTIONAL_CUTS: 'promotional-cuts',
    STOCKS_CAROUSEL: 'stocks-carousel',
    STOCKS_DETAILS: 'stock-details',
};
