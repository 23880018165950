export enum BUTTON_TYPE {
    PRIMARY = 'PRIMARY',
    SECONDARY = 'SECONDARY'
}

export enum BUTTON_LAYOUT {
    FULL = 'FULL',
    ADJACENT = 'ADJACENT'
}

export enum BOOL_ENUM {
    TRUE = 'true',
    FALSE = 'false'
}

export enum STATUS {
    SUCCESS = 'SUCCESS',
    FAILED = 'FAILED'
}

export enum APP_UPDATE_TYPE {
    optional = 'optional',
    mandatory = 'mandatory',
    none = 'none'
}

export interface LatestAppVersion {
    android?: string
    ios?: string
}

export interface AppVersionCheck {
    appUpdateType: APP_UPDATE_TYPE
    latestAppVersion: string | LatestAppVersion
    appUpdateMessage: string
}

export interface IRemoteConfig {
    appVersionCheck: AppVersionCheck
    featureFlags?: Record<string, string>
    checkAppMaintenance: Record<string, any>
}

export enum REMOTE_CONFIG_PARAMETERS {
    FEATURE_FLAGS = 'featureFlags',
    CHECK_APP_MAINTENANCE = 'checkAppMaintenance',
    APP_VERSION_CHECK = 'appVersionCheck'
}

export enum FeatureFlags {
    SHOW_DISCOVER = 'showDiscover',
    SHOW_HOME = 'showHome',
    SHOW_MODEL_DETAILS = 'showModelDetails',
    SHOW_COIN_LEDGER = 'showCoinLedger',
    SHOW_ALL_PENDING_ACTIONS = 'showAllPendingActions',

    SHOW_OPEN_POSITIONS = 'showOpenPositions',
    SHOW_LIVE_TRADES = 'showLiveTrades',
    SHOW_MODEL_INSTRUMENT_CARD = 'showModelInstrumentCard',
    SHOW_TRADE_LOGS = 'showTradeLogs',
    SHOW_PILLAR_DETAILS = 'showPillarDetails',
    SHOW_STORIES = 'showStories',
    SHOW_USER_SUBSCRIPTION = 'showUserSubscriptions',
    SHOW_ONBOARDING_QUESTIONS = 'showOnboardingQuestions',
    SHOW_REFER_FRIENDS = 'showReferFriends',
    SHOW_MODEL_CARD_LIVE_RETURNS = 'showModelCardLiveReturns',
    SHOW_MODEL_DETAILS_PAGE_LIVE_RETURNS = 'showModelDetailsPageLiveReturns',
    SHOW_OPEN_POSITIONS_LIVE_RETURNS = 'showOpenPositionsLiveReturns',
    SHOW_MODEL_LIST = 'showModelList',
    SHOW_SUPRSEND_INBOX = 'showSuprSendInbox',
    SHOW_IN_APP_REVIEW = 'showInAppReview',
    ENABLE_INTRADAY_FNO_BROKER_INTEGRATION = 'enableIntradayAndFnoBrokerIntegration',
    ENABLE_ANDROID_SCREENSHOT_TRACKING = 'enableAndroidScreenshotTracking',
    ENABLE_ANDROID_SCREENSHOT_TRACKING_WITHOUT_PERMISSION = 'enableAndroidScreenshotTrackingWithoutPermission',
    ENABLE_IOS_SCREENSHOT_TRACKING = 'enableIosScreenshotTracking',
    SHOW_MINT_BUZZ_SECTION = 'showMintBuzzSection',
    MAAT_FORM_ENABLED_MODELS = 'maatFormEnabledModels',
    ENABLE_UX_CAM = 'enableUXCam'
}

export enum REACT_QUERY_FETCH_STATUS {
    SUCCESS = 'success',
    LOADING = 'loading',
    ERROR = 'error'
}

export enum ONBOARDING_LANGUAGES {
    ENGLISH = 'en',
    HINDI = 'hi'
}

export enum SwitchEnum {
    ENABLED = 'ENABLED',
    DISABLED = 'DISABLED'
}

export enum BannerType {
    BANNER_WITH_MAIN_ICON = 'BANNER_WITH_MAIN_ICON',
    BANNER_WITH_HEADING_ICON = 'BANNER_WITH_HEADING_ICON'
}

export enum DURATION {
    SECOND = 1000,
    MINUTE = 100 * 60,
    HOUR = 100 * 60 * 60,
    DAY = 100 * 60 * 60 * 24
}
