export const SCREEN_NAME = {
    phone_verification: 'Phone Verification Screen',
    dashboard: 'Web Dashboard',
    login: 'Login Screen',
    qr_code_screen: 'QR Code Screen',
    action: 'Execution Flow Screen',
    model_details: 'Model Details Screen',
};

export const getScreenBaseOnPathName: any = (path?: string) => {
    switch (path) {
        case '/dashboard':
            return SCREEN_NAME.dashboard;
        case '/':
            return SCREEN_NAME.login;
        case '/action':
            return SCREEN_NAME.action;
        case '/model/[id]':
            return SCREEN_NAME.model_details;
    }
    return '';
};
