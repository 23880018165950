import { useRouter } from 'next/router';
import React, { createContext, useContext, useEffect, useState } from 'react';

import { getScreenBaseOnPathName } from '../routingUtils';
import { EVENT_TYPE, trackEvent } from '../trackingUtils';

interface HValidation {
    history: string[];
    previousScreenName: string | null;
    currentScreenName: string | null;
    setHistory(data: string[]): void;
    back(): void;
}

const HistoryContext = createContext<HValidation>({} as HValidation);

export const HistoryProvider: React.FC = ({ children }: any): any => {
    const { asPath, push, pathname } = useRouter();
    const [history, setHistory] = useState<any[]>([]);

    function back() {
        for (let i = history.length - 2; i >= 0; i--) {
            const { asPath } = history[i];

            if (!asPath.includes('#') && asPath !== pathname) {
                push(asPath);

                // if you want to pop history on back
                const newHistory = history.slice(0, i);
                setHistory(newHistory);
                break;
            }
        }
    }

    useEffect(() => {
        if (history.at(-1) === asPath) return;
        const previousScreenName = getScreenBaseOnPathName(history?.at(-1)?.pathname);
        const currentScreenName = getScreenBaseOnPathName(pathname);

        if (pathname !== '/model/[id]') {
            trackEvent(EVENT_TYPE.SCREEN_VIEWED, {
                screen_name: currentScreenName,
                from_screen: previousScreenName,
            });
        }

        setHistory((previous) => [...previous, { asPath, pathname }]);
    }, [asPath]);

    const previousScreenName = getScreenBaseOnPathName(history.at(-1)?.pathname);
    const currentScreenName = getScreenBaseOnPathName(history.at(-1)?.pathname);
    return (
        <HistoryContext.Provider
            value={{
                back,
                previousScreenName,
                currentScreenName,
                history,
                setHistory,
            }}
        >
            {children}
        </HistoryContext.Provider>
    );
};

export function useHistory(): HValidation {
    const context = useContext(HistoryContext);
    return context;
}
