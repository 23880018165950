export interface Broker {
    name: string
    id: number
    displayName?: string
    uuid: string
    easyTradeEnabled: boolean
    broker_props?: Record<string, Record<string, string>>
    accessToken?: string
    broker_id: number
    user_id: number
    status: string
    imgUrl: string
    broker: any
    broker_attrs?: Record<string, Record<string, string>>
}

export interface UserBrokerRequest {
    brokerId: number
    brokerAttrs: Record<string, string>
    name: string
    isUpdate?: boolean
}

export interface TokenInfo {
    accessToken: string
    brokerId: string
}

export enum SupportedBrokers {
    ALICEBLUE = 'aliceblue',
    ANGEL = 'angelbroking',
    AXIS = 'axis',
    EDELWEISS = 'edelweiss',
    FIVEPAISA = 'fivepaisa',
    GROWW = 'groww',
    HDFC = 'hdfc',
    ICICI = 'icici',
    IIFL = 'iifl',
    KITE = 'kite',
    KOTAK = 'kotak',
    MOTILAL = 'motilal',
    TRUSTLINE = 'trustline',
    UPSTOX = 'upstox',
    PAYTM = 'paytm',
    DHAN = 'dhan',
    FISDOM = 'fisdom',
    FUNDZ_BAZAAR = 'fundzbazaar',
    NUVAWA_WEALTH = 'nuvawa_health'
}

export enum ORDER_TYPE {
    SELL = 'SELL',
    BUY = 'BUY'
}

export enum SMALLCASE_INTENT {
    CONNECT = 'CONNECT',
    TRANSACTION = 'TRANSACTION'
}

export interface SmallCaseOrderDetails {
    tradingSymbol: string
    quantity?: number
    transactionType: string
    // signalId?: number
    // orderType?: string
    // triggerPrice?: number
}

export interface SmallcaseTransactionInfo {
    intent: SMALLCASE_INTENT
    orderDetails?: SmallCaseOrderDetails[]
    actionId?: string
    modelId?: string
}

export interface SmallcaseBroker {
    broker?: SupportedBrokers
    smallcaseAuthToken?: string
    guestAuthToken?: string
    brokerInfo?: Broker
    scAuthId?: string
    holdingsConsent?: string
}

export interface SmallcaseTransactionResponse {
    transactionId: Record<string, Record<string, string>>
    notes?: string
}

export interface SmallcaseGatewayTxnRespOrderData {
    orderType: 'MARKET' | 'LIMIT'
    product: 'CNC'
    exchange: 'NSE'
    status:
        | 'COMPLETE'
        | 'CANCELLED'
        | 'PLACED'
        | 'PARTIAL'
        | 'REJECTED'
        | 'CANCELLED'
        | 'CANCELLED AMO'
        | 'ERROR'
    quantity: number
    tradingsymbol: string
    transactionType: 'BUY' | 'SELL'
    filledQuantity: number
    averagePrice: number
    exchangeOrderId: number
    errorCode?: string
    statusMessage?: string
    variety: Variety
}

// https://developers.gateway.smallcase.com/docs/transaction-response#sample-responses-sst
export interface SmallcaseGatewayTxnSuccessResponse {
    smallcaseAuthToken: string
    notes: string
    broker: string
    transactionId: string
    data?: {
        batches: Array<{
            filled: number
            variety: 'regular' | 'amo'
            buyAmount: number
            sellAmount: number
            orders: SmallcaseGatewayTxnRespOrderData[]
            unplaced: SmallcaseGatewayTxnRespOrderData[]
            batchId: string
            quantity: number
            status: 'COMPLETED' | 'UNPLACED' | 'MARKEDCOMPLETE' | 'PLACED'
            completedDate: string
            transactionId: string
        }>
    }
}

export interface InvesApiSmallcasePunchOrderRespObj
    extends SmallcaseGatewayTxnRespOrderData {
    brokerUsed?: string
    lifecycleInfo: {
        id: number
        positionType: 'BUY' | 'SELL'
        uuid: string
        signal: {
            id: number
            uuid: string
            instrument: {
                tradingSymbol: string
            }
        }
        actions_signal_lifecycle: {
            lifecycle_id: number
            action_id: number
        }
    }
}

export interface SmallcaseOrderResp {
    orderData: BrokerOrderPlaceResp[]
    orderParameters: Record<string, any>
}

export interface BrokerOrderPlaceResp {
    error: boolean
    msg: string
    order: InvesApiSmallcasePunchOrderRespObj
    variety: Variety
}

export enum BROKER_GATEWAY {
    SMALLCASE = 'SMALLCASE',
    KITE = 'KITE'
}

export interface Order {
    tradingSymbol: string
    exchange: string
    orderType: string
    quantity: number
    productType: string
    price?: number
    triggerPrice?: number
    transactionType: string
    variety: string
    signalLifecycleId?: string
    tag?: string
    validityType?: string
}

export interface SignalDetails {
    signalId: string
    signalUpdateId: string
}

export interface PunchBrokerOrder {
    orders: Order[]
    brokerId?: number
    signalDetails?: SignalDetails
}

export interface MarginInfo {
    availableMargin: number
    totalMarginRequired: number
}

export interface BrokerOrderResponse {
    order_id: string
}

export interface BrokerLoginUrlRequest {
    brokerId: string
}

export interface BrokerLoginUrlResponse {
    url: string
}

export enum Variety {
    REGULAR = 'regular',
    AMO = 'amo'
}

export enum SmallcaseError {
    no_broker = 'No broker found',
    user_cancelled = 'User cancelled the execution',
    trading_not_enabled = "Trading not enabled on user's account",
    api_error = 'Something went wrong',
    market_closed = 'Market closed',
    order_pending = 'Order is in pending status',
    transaction_expired = 'Transaction session expired'
}

export interface InitBrokerOrderRequest {
    actionId: string
    orders: Order[]
    userBrokerId: string
}

export interface InitBrokerOrderResponse {
    tag: string
}

export interface OrderDetailsByTag {
    brokerId: string
    tag: string
    showBrokerReadOnlyAccessModal?: boolean
}

export interface UserBrokers {
    brokers: Broker[]
    preferredBroker: string
}

export interface UserBrokerInformationMappingRequest {
    brokerId?: string
    accessToken?: string
    clientId?: string
}

export interface UserBrokerInformationMappingResponse {
    status: string
}

export interface OrderInitStep {
    enabled: boolean
    getTag: boolean
    orderObjectCreation: boolean
}

export interface InvesBrokerPlaceOrder {
    status: string
    orderId: string
    message: string
}

export interface ExtraOrderCancellationRequest {
    userBrokerId: string
    orders: Order[] | InvesApiSmallcasePunchOrderRespObj[]
}

export interface InvesBrokerPlaceOrderResponse {
    tag: string
    orders: InvesBrokerPlaceOrder[]
}

export interface LoginBrokerStep {
    required: boolean
    fetchLoginUrl: boolean
    sendLoginInformationToServer: boolean
}

export enum PlaceOrderWithBrokerType {
    BROKER_INTERFACE = 'BROKER_INTERFACE',
    API = 'API'
}

export interface PlaceOrderWithBrokerStep {
    enabled: boolean
    type: PlaceOrderWithBrokerType
    orderDetailsByTag: boolean
}

export enum ExtraOrderCancellationType {
    BROKER_ORDER_PAGE_INTERFACE = 'BROKER_ORDER_PAGE_INTERFACE',
    API = 'API'
}

export interface ExtraOrderCancellationStep {
    type: ExtraOrderCancellationType
    required: boolean
}

export enum InvesBrokerExecutionOrderSteps {
    ORDER_INIT = 'orderInit',
    LOGIN = 'login',
    PLACE_ORDER_WITH_BROKER = 'placeOrderWithBroker',
    EXTRA_ORDER_CANCELLATION = 'extraOrderCancellation'
}

export interface InvesBrokerExecutionOrderStepsMapping {
    [InvesBrokerExecutionOrderSteps.ORDER_INIT]: OrderInitStep
    [InvesBrokerExecutionOrderSteps.LOGIN]: LoginBrokerStep
    [InvesBrokerExecutionOrderSteps.PLACE_ORDER_WITH_BROKER]: PlaceOrderWithBrokerStep
    [InvesBrokerExecutionOrderSteps.EXTRA_ORDER_CANCELLATION]: ExtraOrderCancellationStep
}

export interface UserBrokerData {
    supportedIntradayBroker: Broker[]
    userSelectedBrokerInfo: Broker
    isBrokerUserDataLoading: boolean
    userSmallcaseBrokerData: SmallcaseBroker
    invesBrokerUserData: UserBrokers
    isSmallcaseExecutionFlow?: boolean
    doesUserIntradayBrokerAccessValid?: boolean
    doesBrokerLinked?: boolean
    connectedBrokerDisplayName: string | null
    doesLinkedBrokerAccessValid?: boolean
    refetchUserSmallcaseBrokers: Function
    isRefetchingSmallcaseBrokers: boolean
    isValidatingUserBrokerAccess?: boolean
    setRefetchData?: Function
}
