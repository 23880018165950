export function setCookie(name: string, value: string, days: number) {
    let expires = '';
    if (days) {
        let date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = '; expires=' + date.toUTCString();
    }
    document.cookie = name + '=' + (value || '') + expires + '; path=/';
}

export function getCookie(name: string, ctx?: any) {
    if (ctx?.req) return ctx.req.cookies[name];
    const cookie = typeof document !== 'undefined' ? document.cookie : '';
    const value = `; ${cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
        // @ts-ignore
        return parts.pop().split(';').shift();
    }
}
