import styled from 'styled-components';

import { Spinner } from '@/constants/icons';

export const LoadingScreen = ({ isDark = false }) => (
    <LoadingWrapper isDark={isDark}>
        <div className="flex flex-col items-center gap-8">
            <Spinner height={28} width={28} />
            <div className={`tracking-2 ${isDark ? 'text-white-100' : ''}`}>Loading</div>
        </div>
    </LoadingWrapper>
);

const LoadingWrapper = styled.div`
    display: flex;
    width: 100%;
    height: 100vh;
    align-items: center;
    justify-content: center;
    background-color: #fff;
`;
