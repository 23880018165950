export enum EventNames {
    ERROR = 'error',
    WIDGET_IMPRESSION = 'WIDGET_IMPRESSION',
    SWIPED_BUTTON = 'swiped_button',
    SCREEN_VIEW = 'screen_view',
    CLICKED_BUTTON = 'clicked_button',
    CLICKED_TAB = 'clicked_tab',
    CLICKED_SIGNAL = 'clicked_signal',
    CLICKED_STORY = 'clicked_story',
    CLICKED_STOCK = 'clicked_stock',
    BOTTOM_SHEET_VIEWED = 'bottom_sheet_viewed',
    LOGOUT = 'logout',
    VALIDATION_FAILURE = 'validation_failure',
    REFRESH = 'refresh',
    CLICKED_CUT = 'clicked_cut',
    DETAILS_ADDED = 'details_added',
    DETECTED_DYNAMIC_LINK = 'detected_dynamic_link',
    DETECTED_AF_ONELINK = 'detected_af_onelink',
    DETECTED_AF_DEEPLINK = 'detected_af_deeplink',
    CLICKED_HYPERLINK = 'clicked_hyperlink',
    API_FAILURE = 'api_failure',
    VERIFICATION_FAILURE = 'verification_failure',
    LOGIN = 'login',
    SIGNUP = 'signup',
    CLICKED_MODEL = 'clicked_model',
    SUBSCRIBED = 'subscribed',
    UNSUBSCRIBED = 'unsubscribed',
    TRACKING = 'tracking',
    TRADING = 'trading',
    TRACKING_FAILURE = 'tracking_failure',
    TRADING_FAILURE = 'trading_failure',
    FORM_FILL = 'form_fill',
    APP_TRACKING = 'app_tracking',
    STOCK_FILTERS = 'clicked_stock_card_filter',
    STOCK_DETAILS_PAGE_VIEW = 'stock_screen_viewed',
    MODEL_PILLS_CLICKED = 'clicked_stock_screen_model_pill',
    STOCK_CARD_CLICKED = 'clicked_stock_card',
    CLICKED_NEWS_ARTICLE = 'clicked_news_article',
    BROKER_LOGIN_FROM_APP = 'broker_login_app',
    CLICKED_PROMOTIONAL_CAROUSEL = 'clicked_promotional_carousel',
    INVITE_SHARED = 'invite_shared',
    QUIZ_ANSWERED = 'quiz_answered',
    QUIZ_COMPLETED = 'quiz_completed',
    RESOLVE_DYNAMIC_LINK = 'resolve_dynamic_link',
    SCREENSHOT_CAPTURED = 'screenshot_captured',
    DETAILS_UPDATED = 'details_updated',
    BROKER_LINKED = 'broker_linked',
    TRADING_WITHOUT_READ = 'trading_without_read',
    NOTIFICATION_PREFERENCES_SWITCHED = 'notification_preference_switched',
    REDEMPTION_FLOW_COMPLETED = 'redemption_flow_completed',
    BANNER_VIEWED = 'banner_viewed',
    STARTED_FEED_SESSION = 'started_feed_session',
    ENDED_FEED_SESSION = 'ended_feed_session',
    CLICKED_FILTER = 'clicked_filter',
    MINT_BUZZ_CARD_VIEWED = 'mintbuzz_card_viewed',
    MINT_BUZZ_CARD_VIEW_COMPLETED = 'mintbuzz_card_view_completed',
    CLICKED_MINTBUZZ_CARD = 'clicked_mintbuzz_card',
    CLICKED_IMPACT = 'clicked_impact',
    FOLLOWED_STOCK = 'followed_stock',
    UNFOLLOWED_STOCK = 'unfollowed_stock',
    PORTFOLIO_SYNCED = 'portfolio_synced',
    MODEL_CONFIGURATION_UPDATED = 'model_configuration_updated',
    MODEL_CONFIG_RETURNS_GENERATED = 'model_config_returns_generated'
}

export enum EventProperty {
    SUBSCRIPTION_TYPE = 'SUBSCRIPTION_TYPE',
    STEP = 'STEP',
    IS_AMO = 'is_amo',
    TRADED = 'traded',
    TRADING_TYPE = 'trading_type',
    LIFECYCLE_COUNT = 'lifecycle_count',
    EXECUTION_TYPE = 'execution_type',
    PARTIALLY_EXECUTED = 'partially_executed',
    IS_RETRY_ORDER = 'is_retry_order',
    INVESTED_AMOUNT = 'invested_amount',
    BUTTON_TYPE = 'button_type',
    IS_DEBUG_APP = 'IS_DEBUG_APP',
    FAILURE_ID = 'failure_id',
    E_MESSAGE = 'e_message',
    E_TAG = 'e_tag',
    SCREEN_NAME = 'screen_name',
    FROM_SCREEN = 'from_screen',
    ACCESS_TYPE = 'access_type',
    BUTTON_ID = 'button_id',
    CUT_NAME = 'cut_name',
    CUT_UNIQUE_KEY = 'cut_unique_key',
    MODEL_NAME = 'model_name',
    STORY_NAME = 'story_name',
    TIME_PERIOD = 'time_period',
    ACTION_COUNT = 'action_count',
    ACTION_UUID = 'action_uuid',
    MODEL_UNIQUE_KEY = 'model_unique_key',
    INSTRUMENT = 'instrument',
    EXECUTION_TIME = 'execution_time',
    URL = 'url',
    NAME = 'name',
    ERROR_MESSAGE = 'error_message',
    PHONE_NUMBER = 'phone_number',
    EMAIL = 'email',
    UTM_SOURCE = 'utm_source',
    UTM_MEDIUM = 'utm_medium',
    UTM_CAMPAIGN = 'utm_campaign',
    UTM_CONTENT = 'utm_content',
    DEEP_LINK_VALUE = 'deep_link_value',
    UUID = 'uuid',
    EVENT_TYPE = 'event_type',
    API_ID = 'api_id',
    SECTION = 'section',
    BUTTON_TEXT = 'button_text',
    TRADING_SYMBOL = 'trading_symbol',
    POSITION_TYPE = 'position_type',
    PRODUCT_TYPE = 'product_type',
    FORM_ID = 'form_id',
    STATUS = 'status',
    TYPE = 'type',
    VALUE = 'value',
    MODEL_LIST = 'model_list',
    STOCK_NAME = 'stock_name',
    HOLDING = 'holding',
    FOLLOWING = 'following',
    FOLLOWING_TYPE = 'following_type',
    TIME_WINDOW = 'time_window',
    INSIGHTS = 'insights',
    TAB_NAME = 'tab_name',
    PREV_VALUE = 'previous_value',
    TRIGGERED_EXECUTION_FLOW = 'triggered_execution_flow',
    TARGET_TYPE = 'target_type',
    OPEN = 'open',
    CLOSE = 'close',
    ANALYSIS = 'analysis',
    ARTICLE_TITLE = 'article_title',
    ORGANISATION = 'organization',
    POSTED_AT = 'posted_at',
    RATING = 'rating',
    INVEST_MORE = 'invest_more',
    CURRENT_BALANCE = 'current_balance',
    LIFETIME_BALANCE = 'lifetime_balance',
    STATE = 'state',
    REFERRED_SIGNUP_COUNT = 'referred_signup_count',
    REFERRED_TRADE_COUNT = 'referred_trade_count',
    CHANNEL = 'channel',
    REFERRER = 'referrer',
    COIN_EARNED = 'coins_earned',
    QUESTION = 'question',
    ANSWER = 'answer',
    FIRST_TRADE = 'first_trade',
    ACTION_TYPE = 'action_type',
    VARIETY = 'variety',
    STEADY_STATE = 'steady_state',
    ZERO_STATE = 'zero_state',
    ACTIONABLE_BOOLEAN = 'actionables_boolean',
    LIVE_SIGNALS = 'number_of_signals_live',
    INVESTED = 'invested',
    CURRENT = 'current',
    RETURN = 'return',
    RETURN_PERCENTAGE = 'return_percentage',
    POSITION_RETURNS = 'positions_return_percentage',
    PRIMARY_URL = 'primary_url',
    SECONDARY_URL = 'secondary_url',
    SIGNAL_TYPE = 'signal_type',
    SIGNAL_USER_STATE = 'signal_user_state',
    IS_PARTIAL_ORDER = 'is_partial_order',
    SIGNAL_DATE = 'SIGNAL_DATE',
    VIEW_TYPE = 'view_type',
    ONBOARDING_SCREEN_ORDER = 'onboarding_screen_order',
    INTERACTION_TYPE = 'interaction_type',
    ONBOARDING_SCREEN_HEADER = 'onboarding_screen_header',
    LANGUAGE_SELECTED = 'language_selected',
    KNOWN_USER = 'known_user',
    BROKER_TYPE = 'broker_type',
    BROKER_NAME = 'broker_name',
    VIDEO_TITLE = 'video_title',
    VIDEO_ORDER = 'video_order',
    EXPLAINER_STORIES_HIDDEN = 'explainer_stories_hidden',
    CANCEL_SL_PENDING = 'cancel_sl_pending',
    EXIT_TYPE = 'exit_type',
    ENTRY_ORDER_TYPE = 'entry_order_type',
    PREVIOUS_BROKER = 'previous_broker',
    CHANGE_OR_ADD = 'change_or_add',
    CHANNEL_TYPE = 'channel_type',
    IS_ENABLED = 'is_enabled',
    REWARD_TITLE = 'reward_title',
    MINTCOIN_VALUE = 'mintcoin_value',
    PLATFORM = 'platform',
    ACTION_CATEGORY = 'action_category',
    MARKET_OPEN = 'market_open',
    AMO_POSSIBLE = 'amo_possible',
    BROKER_ADDED = 'broker_added',
    MODEL_PAGE_DEEPLINK = 'model_page_deeplink',
    SIGNAL_DETAILS_DEEPLINK = 'signal_details_deeplink',
    ENTITY_NAME = 'entity_name',
    MINT_BUZZ_CARD_CATEGORY = 'mintbuzz_card_category',
    IMPACT = 'impact',
    MINTBUZZ_CARD_ID = 'mintbuzz_card_id',
    EMITTED_AT = 'emitted_at',
    TEXT_STRING = 'text_string',
    STOCK_CAP_CATEGORY = 'stock_cap_category',
    SECTOR = 'sector',
    INSIDE_VIEW_MORE = 'inside_view_more',
    BOTTOM_SHEET_NAME = 'bottom_sheet_name',
    QUANTITY = 'quantity',
    TOTAL_AMOUNT = 'total_amount',
    FUNDS_REQUIRED = 'funds_required',
    ORDER_TYPE = 'order_type',
    BROKER_LINKED = 'broker_linked',
    LTP = 'ltp',
    STOPLOSS_PERCENTAGE = 'stoploss_percentage',
    TARGET_PERCENTAGE = 'target_percentage',
    STOPLOSS_ADDED = 'stoploss_added',
    TARGET_ADDED = 'target_added',
    BANNER_ID = 'banner_id',
    MODEL_OR_STOCK_NAME = 'model_or_stock_name',
    NUMBER_OF_MINT_BUZZ_CARDS_AVAILABLE = 'number_of_mintbuzz_cards_available',
    CLUBBING_TYPE = 'clubbing_type',
    CLUB_VALUE = 'club_value',
    TOP_MINTBUZZ_CARD_ID = 'top_mintbuzz_card_id',
    STOCK_OR_MODEL_NAME = 'stock_or_model_name',
    POSITION_IN_FEED = 'position_in_feed',
    MINTBUZZ_CARD_CATEGORY = 'mintbuzz_card_category',
    SUBSCRIBED = 'subscribed',
    RETURNS = 'returns',
    REOMMENDED_AT_PRICE = 'recommended_at_price',
    RECOMMENDATION_TYPE = 'recommendation_type',
    APPLIED_FILTERS = 'applied_filters',
    CLUBBED_MINTBUZZ_CARDS_ID = 'clubbed_mintbuzz_cards_id',
    POSITION_IN_VIEW_MORE = 'position_in_view_more',
    TIME_VIEWED = 'time_viewed',
    IS_BOTTOMSHEET = 'is_bottomsheet',
    MARKET_CAP = 'market_cap',
    FOLLOW_LIST = 'follow_list',
    FILTER_NAME = 'filter_name',
    STOCKS_LIST = 'stocks_list',
    NUMBER_OF_STOCKS = 'number_of_stocks',
    TAB = 'tab',
    CONFIG_ID = 'config_id',
    UPCOMING_CONFIG_ID = 'upcoming_config_id',
    MOST_POPULAR = 'most_popular',
    FIELD_NAME = 'field_name',
    FIELD_VALUE = 'field_value',
    RESET_TO = 'reset_to',
    DRAWDOWN = 'drawdown',
    MOST_POPULAR_RETURNS = 'most_popular_returns',
    MOST_POPULAR_DRAWDOWN = 'most_popular_drawdown',
    PREV_RETURNS = 'prev_returns',
    PREV_DRAWDOWN = 'prev_drawdown',
    PREV_CONFIG_ID = 'previous_config_id',
    PREV_UPCOMING_CONFIG_ID = 'previous_upcoming_config_id',
    IS_UPCOMING_PREFERENCE = 'is_upcoming_preference',
    IS_RESET_TO_MODEL_DEFAULT = 'is_reset_to_model_default',
    TARGET = 'target',
    STORY_TITLE = 'story_title',
    NUMBER_OF_MINTBUZZ_CARDS_VIEWED = 'number_of_mintbuzz_cards_viewed',
    CARD_COLOUR = 'card_colour'
}

export enum UserProperties {
    EMAIL = 'email',
    MIXPANEL_EMAIL = '$email',
    MIXPANEL_NAME = '$name',
    PHONE_NUMBER = 'phone_number',
    NAME = 'name',
    PLATFORM = 'platform',
    UTM_SOURCE = 'utm_source',
    UTM_MEDIUM = 'utm_medium',
    UTM_CAMPAIGN = 'utm_campaign',
    UTM_CONTENT = 'utm_content',
    APP_TRACKING_PERMISSION = 'app_tracking_permission',
    EXTERNAL_STORAGE_PERMISSION = 'external_storage_permission',
    REFERRER = 'referrer',
    CURRENT_MINTCOINS = 'current_balance',
    LIFETIME_BALANCE = 'lifetime_balance'
}

export enum ButtonId {
    OPEN_PORTFOLIO_SIGNALS = 'open_portfolio_signals',
    OPEN_BROKER_SIGNALS_SCREEN = 'open_broker_signals_screen',
    OPEN_PORTFOLIO_SCREEN = 'open_portfolio_screen',
    OPEN_MODEL_LISTING_SCREEN = 'open_models_listing_screen',
    OPEN_SPOT_SIGNALS_SCREEN = 'open_spot_signals_screen',
    OPEN_ALL_SIGNALS_SCREEN = 'open_all_signals_screen',
    OPEN_TICKER_TREND_BOTTOM_SHEET = 'open_ticker_trend_bottom_sheet',
    OPEN_ADDITIONAL_SIGNALS_FILTERS_BOTTOM_SHEET = 'open_additional_signal_filters_bottom_sheet',
    SUBSCRIBE_STEP_CONTINUE = 'SUBSCRIBE_STEP_CONTINUE',
    UPDATE_PREFERENCES = 'update_preferences',
    STARTED_LOGIN = 'started_login',
    GET_OTP = 'get_otp',
    RESEND_OTP = 'resend_otp',
    EDIT_PHONE_NUMBER = 'edit_phone_number',
    COIN_LEDGER = 'COIN_LEDGER',
    OPEN_SIGNALS = 'open_signals',
    OPEN_POSITIONS = 'open_positions',
    OPEN_GLOSSARY = 'open_glossary',
    OPEN_DASHBOARD_TABS = 'open_dashboard_tabs',
    ORDER_EXECUTION_FLOW = 'order_execution_flow',
    UNSUBSCRIBE_MODAL = 'unsubscribe_modal',
    UNSUBSCRIBE = 'unsubscribe',
    CONTACT_US = 'CONTACT_US',
    SUBSCRIBE = 'subscribe',
    GLOSSARY = 'glossary',
    EXPAND_SIGNAL = 'expand_signal',
    VERIFY_OTP = 'verify_otp',
    INVITE = 'invite',
    SHARE = 'share',
    EXPLAINER_TILE = 'explainer_tile',
    SIGNAL_TIMELINE = 'signal_timeline',
    ALL_TRADES = 'all_trades',
    LATEST_CLOSED_TRADES = 'latest_closed_trades',
    MODEL_FACTSHEET = 'model_factsheet',
    DOWNLOAD_FACTSHEET = 'download_factsheet',
    FACTSHEET_PAGE = 'factsheet_page',
    CHANGE_QUANTITIES = 'change_quantities',
    CHANGE_PREFERRED_AMOUNT = 'change_preferred_amount',
    TRADE = 'trade',
    TRADE_TRY_AGAIN = 'trade_try_again',
    TRACK = 'track',
    PROFILE = 'profile',
    ADD_DETAILS = 'add_details',
    UPDATE_DETAILS = 'update_details',
    MODEL_ACTIONABLE = 'model_actionable',
    REQUEST_MODEL_ACCESS = 'request_model_access',
    RENEW_MODEL_ACCESS = 'renew_model_access',
    EXTEND_MODEL_ACCESS = 'extend_model_access',
    STOCK_DETAILS_TAB = 'stock_details_tab',
    STOCK_SCREEN_TRADE_BUTTON = 'stock_screen_invest_via',
    VIEW_STOCK_DETAILS = 'view-stock-details-page',
    DISCOVER_SCREEN = 'view-discover-screen',
    DONT_SHOW_EXPLAINER_CARD = 'dont_show_explainer_cards',
    JOIN_DISCUSSION_EXPLAINER_CARD = 'join_discussion_explainer_cards',
    EXPLAINER_GO_TO_MODEL_LIST = 'explainer_model_list',
    EXPLAINER_SEND_ME_A_SIGNAL = 'explainer_signal_send_signal',
    EXPLAINER_JOIN_COMMUNITY = 'explainer_join_community',
    EXPLAINER_KNOW_MORE = 'explainer_model_know_more',
    EXPLAINER_INVEST_BUTTON = 'explainer_model_invest_now',
    TUTORIAL_CARDS = 'tutorial_cards',
    MODEL_COMPARISON_TABLE = 'model_comparison_table',
    ACTIONABLE_SIGNALS = 'user_actionable_signals',
    JOIN_MEMBER_COMMUNITY = 'open_join_telegram_community',
    LONG_INDIA_CARD_SUBSCRIBE = 'long_india_card_subscribe',
    LONG_INDIA_CARD_INVEST = 'long_india_card_invest_100',
    DONT_SHOW_EXPLAINER_CARD_ON_EXECUTION_FLOW = 'dont_show_explainer_cards_on_execution_screen',
    EXPLAIN_ON_EXECUTION_FLOW = 'explainer_on_execution_flow',
    CONVERT_TO_TRADING = 'convert_to_trading',
    STOCK_CARD_FILTERS = 'stock_card_filters',
    STOCK_CARD_SORTING = 'stock_card_sorting',
    OTHER_STOCKS = 'others_stocks_icons',
    MODEL_PILLS_CLICKED = 'model_pills_clicked_stock_details',
    IN_APP_REVIEW_SUBMIT = 'in_app_review_submit',
    IN_APP_REVIEW_CANCELLED = 'in_app_review_cancelled',
    INVEST_MORE = 'invest_more',
    OPEN_REFERRAL_SCREEN = 'open_referrals_screen',
    OPEN_EARNING_SCREEN = 'open_earnings_screen',
    OPEN_REFERRAL_FAQ = 'open_referrals_FAQ',
    OPEN_REFERRAL_SUPPORT = 'open_referrals_support',
    OPEN_REFERRAL_TnC = 'open_referrals_tnc',
    OPEN_EARNING_FAQ = 'open_earnings_FAQ',
    OPEN_EARNING_SUPPORT = 'open_earnings_support',
    OPEN_EARNING_TnC = 'open_earning_tnc',
    OPEN_REFERRALS_BOTTOM_SHEET = 'open_referrals_bottom_sheet',
    OPEN_REWARDS_BOTTOM_SHEET = 'open_rewards_bottom_sheet',
    OPEN_REFERRAL_FLOW = 'open_referral_flow',
    OPEN_DISCOVER_SCREEN = 'open_discover_screen',
    OPEN_SYNC_PORTFOLIO = 'open_sync_portfolio',
    OPEN_PROFILE_SCREEN = 'open_profile_screen',
    OPEN_COIN_LEDGER_SCREEN = 'open_coins_ledger_screen',
    OPEN_MINT_COIN_TUTORIAL_BOTTOM_SHEET = 'open_mint_coin_tutorial_bottom_sheet',
    OPEN_SIGNAL_DISMISSAL_POPUP = 'open_signal_dismissal_popup',
    CLOSE_SIGNAL_DISMISSAL_POPUP = 'close_signal_dismissal_popup',
    SIGNAL_DISMISSAL_POPUP = 'signal_dismissal_popup',
    OPEN_MODEL_SCREEN = 'open_model_screen',
    OPEN_HOME_SCREEN = 'open_home_screen',
    DISMISS_NOTICE_BUTTON = 'dismiss_notice_board',
    OPEN_DASHBOARD_SIGNALS = 'open_dashboard_signals_screen',
    OPEN_DASHBOARD_PNL = 'open_dashboard_pnl',
    OPEN_MODEL_EXPLAINER_VIDEO = 'open_model_explainer_video',
    DISMISS_MODEL_EXPLAINER_VIDEO = 'dismiss_model_explainer_video',
    DISMISS_SIGNAL = 'dismiss_signal',
    OPEN_PAST_TRADES_SCREEN = 'open_past_trades_screen',
    OPEN_PNL_BOTTOM_SHEET = 'open_pnl_dashboard_bottom_sheet',
    EXIT_POSITIONS_MANUALLY = 'exit_positions_manually',
    LANGUAGE_CHANGE = 'changed_language',
    OPEN_NEXT_PRE_ONBOARDING_SCREEN = 'open_next_pre_onboarding_screen',
    OPEN_LOGIN_SCREEN = 'open_login_screen',
    OPEN_POST_ONBOARDING_SCREEN = 'open_post_onboarding_screen',
    OPEN_PREVIOUS_POST_ONBOARDING_SCREEN = 'open_previous_post_onboarding_screen',
    OPEN_NEXT_POST_ONBOARDING_SCREEN = 'open_next_post_onboarding_screen',
    PHONE_TEXT_FIELD = 'phone_text_field',
    NAME_TEXT_FIELD = 'name_text_field',
    EMAIL_TEXT_FIELD = 'email_text_field',
    SHOW_INVITE_LINK_FIELD = 'show_invite_link_field',
    OPEN_BROKER_SCREEN = 'open_broker_screen',
    OPEN_NOTIFICATION_PREFERENCES_SCREEN = 'open_notification_preferences_screen',
    OPEN_ADD_BROKER_FLOW = 'open_add_broker_flow',
    OPEN_BROKER_FLOW = 'open_broker_flow',
    OPEN_CHANGE_BROKER_FLOW = 'open_change_broker_flow',
    EXPLAINER_MODEL_LIST = 'explainer_model_list',
    OPEN_EXPLAINER_VIDEO = 'open_explainer_video',
    OPEN_POST_LOGIN_ONBOARDING = 'open_post_login_onboarding_screen',
    OPEN_CANCEL_SL_FLOW = 'open_cancel_sl_flow',
    OPEN_READ_ACCESS_BOTTOM_SHEET = 'open_read_access_bottom_sheet',
    OPEN_READ_ACCESS_FLOW = 'open_read_access_flow',
    OPEN_SL_CANCEL_BOTTOM_SHEET = 'open_cancel_sl_bottom_screen',
    OPEN_SL_CANCEL_FLOW = 'open_cancel_sl_flow',
    DISMISS_MANUAL_TRADE_BOTTOM_SHEET = 'dismiss_manual_trade_bottom_sheet',
    OPEN_MANUAL_TRADE_BOTTOM_SHEET = 'open_manual_trade_bottom_sheet',
    OPEN_EXIT_CONFIRMATION_BOTTOM_SHEET = 'open_exit_confirmation_bottom_screen',
    DISMISS_EXIT_CONFIRMATION_BOTTOM_SHEET = 'dismiss_exit_confirmation_bottom_screen',
    NOTIFY_REWARDS_LAUNCH = 'notify_rewards_launch',
    OPT_NOTIFICATIONS_PREFERENCES = 'op_notification_preference',
    OPEN_REDEMPTION_SCREEN = 'open_redemption_screen',
    OPEN_REWARD_REDEMPTION_FLOW = 'open_reward_redemption_flow',
    REWARD_REDEMPTION_ATTEMPTED = 'reward_redemption_attempted',
    OPEN_ZERODHA_STEPS_TO_REDEEM = 'open_zerodha_steps_to_redeem',
    OPEN_STOCK_GIFTING_STEPS_BOTTOM_SHEET = 'open_stock_gifting_steps_bottom_sheet',
    SUPPORT = 'Support',
    TAB_EARNINGS = 'tab_earnings',
    TAB_REDEEMED = 'tab_redeemed',
    NOTIFY_MARKET_OPEN = 'notify_market_open',
    OPEN_SIGNAL_ACTIONS_BOTTOM_SHEET = 'open_signal_actions_bottom_sheet',
    OPEN_SIGNAL_NEWS_BOTTOM_SHEET = 'open_signal_news_bottom_sheet',
    EXPAND_MODEL_SECTION = 'expand_model_section',
    COLLAPSE_MODEL_SECTION = 'collapse_model_section',
    CHANGE_POSITION_TYPE = 'change_position_type',
    CHANGE_ORDER_TYPE = 'change_order_type',
    OPEN_ORDER_SUMMARY_SCREEN = 'open_order_summary_screen',
    OPEN_MINTBUZZ_CARD_AI_BOTTOM_SHEET = 'open_mintbuzz_card_ai_bottom_sheet',
    OPEN_CHANGE_AMOUNT_BOTTOM_SHEET = 'open_change_amount_bottom_sheet',
    CHANGE_STOCK_QUANTITY = 'change_stock_qty',
    SAVE_AMOUNT = 'save_amount',
    OPEN_ORDER_THREE_DOT_MENU = 'open_order_three_dot_menu_bottom_sheet',
    STOPLOSS = 'stoploss',
    TARGET = 'target',
    CHANGE_STOPLOSS = 'change_stoploss',
    CHANGE_TARGET = 'change_target',
    OPEN_EXECUTION_FLOW = 'open_execution_flow',
    OPEN_ORDER_SETTING_BOTTOM_SHEET = 'open_order_settings_bottom_sheet',
    SAVE_POSITION_TYPE = 'save_position_type',
    OPEN_MANAGE_FOLLOWING_SCREEN = 'open_manage_following_screen',
    OPEN_PAST_SIGNAL_SCREEN = 'open_past_signals_screen',
    OPEN_MORE_MINTBUZZ_CARDS_BOTTOM_SHEET = 'open_more_mintbuzz_cards_bottom_sheet',
    OPEN_PORTFOLIO_SYNC_FLOW = 'open_portfolio_sync_flow',
    OP_SPOT_SIGNAL_NOTIFICATIONS = 'op_spot_signal_notifications',
    OPEN_SPOT_SIGNAL_BOTTOM_SHEET = 'open_spot_signal_bottom_sheet',
    DISMISS_SPOT_SIGNAL_BANNER = 'dismiss_spot_signal_banner',
    SHARED_MINT_BUZZ_CARD_FLOW = 'share_mintbuzz_card_flow',
    SHARED_MINT_BUZZ_CARD = 'share_mintbuzz_card',
    OPEN_MODEL_FORM = 'open_model_form',
    OPEN_MODEL_FIELD = 'op_model_field',
    OP_RESET_MODEL_FORM = 'op_reset_model_form',
    OP_GENERATE_MODEL_RETURNS = 'op_generate_model_returns',
    OPEN_MODEL_CONFIGURATION_DETAILS = 'open_model_configuration_details',
    OP_MODEL_CONFIGURATION_UPDATES = 'op_model_configuration_updated',
    OPEN_EXPLAINER_STORY = 'open_explainer_story',
    OPEN_PORTFOLIO_LIST_BOTTOM_SHEET = 'open_portfolio_list_bottom_sheet'
}

export enum BannerId {
    BROKER_CONNECTED_NOT_LINKED = 'broker_connected_not_linked',
    BROKER_NOT_LINKED = 'broker_not_linked',
    BROKER_LINKED_AND_CONNECTED = 'broker_linked_and_connected'
}

export enum InputTypes {
    PHONE_NUMBER = 'phone_number',
    OTP = 'OTP'
}

export enum EventType {
    TRIGGER_EVENT = 'trigger_event',
    CONVERSION_EVENT = 'conversion_event',
    FAILURE_EVENT = 'failure_event'
}

export enum ApiId {
    GET_OTP = 'get_otp',
    STORY = 'story'
}

export enum FormId {
    MODEL_SUBSCRIPTION = 'model_subscription'
}

export enum EventValues {
    LONG_INDIA_INGRESS = 'Long India Ingress',
    ACTIONABLES = 'Your Actionables',
    ACTIONABLES_TAB = 'Actionables',
    NOTIFICATION = 'Notification',
    NOTIFICATION_CENTER = 'Notification Center',
    WATCHLIST = 'Watchlist',
    BOTTOM_BAR = 'Bottom Nav',
    YOUR_PNL = 'Your P&L',
    DO_MORE = 'Do More',
    START_TRADING_JOURNEY = 'Start your trading journey',
    EXPLORE_MODEL_CARDS = 'Explore Models Card',
    ORDER_BOTTOM_SHEET = 'Order Bottom Sheet',
    EXIT_CONFIRMATION_BOTTOM_SHEET = 'Exit Confirmation Bottom Sheet',
    STORY_CLICK = 'story_click',
    CLICK = 'click',
    STEADY_STATE = 'steady_state',
    ZERO_STATE = 'zero_state',
    CNC = 'CNC',
    INTRADAY = 'INTRADAY',
    EXPLAINER_CARDS = 'Explainer cards',
    POPULAR_MODELS_SECTION = 'Popular models to get started',
    COIN_PILL = 'Coins Pill',
    MINT_CLUB_BANNER = 'Mint Club Banner',
    ANDROID_POP_UP = 'android_pop_up',
    READ_ACCESS = 'read_access',
    WRITE_ACCESS = 'write_access',
    BOTTOM_SHEET = 'bottom_sheet',
    DISCRETIONARY = 'discretionary',
    POST_SIGNAL_EXIT = 'post_exit_signal',
    BROKER_ORDER = 'broker',
    MANUAL_ORDER = 'manual',
    REWARDS_BOTTOM_SHEET = 'Rewards Bottom Sheet',
    REWARDS_BANNER = 'Rewards Banner',
    EARNING_HERO_SECTION = 'Earning Hero Section',
    CHANGE = 'change',
    ADD = 'add',
    GLOBAL_BANNER = 'global_banner',
    WHATSAPP = 'whatsapp',
    PUSH = 'push',
    EMAIL = 'email',
    TRANSACTIONAL = 'transactional',
    PROMOTIONAL = 'promotional',
    WEB = 'web',
    BUZZ = 'buzz',
    QUANT = 'quant',
    SPOT = 'spot',
    TRUE = 'True',
    FALSE = 'False',
    MODEL_SECTION = 'Model Section',
    EXPIRED_BOTTOM_SHEET = 'Expired Bottom Sheet',
    ACTIVE_SIGNAL_SECTION = 'Active Signals',
    FOLLOWING_STORIES = 'following Stories',
    OPEN_PORTFOLIO_SYNC_FLOW = 'open_portfolio_sync_flow',
    EDIT_CONFIGURATION_CTA = 'Edit Configuration CTA',
    EDIT_UPCOMING_CONFIGURATION_CTA = 'Edit Upcoming Configuration CTA',
    GET_STARTED_CTA = 'Get Started CTA',
    RESET = 'Reset Button',
    FIELD = 'Field',
    MOST_POPULAR = 'Most Popular',
    PREVIOUS_CONFIG = 'Previous Config',
    PRIMARY_CTA = 'Primary CTA',
    SECONDARY_CTA = 'Secondary CTA',
    COMPARISON_TABLE = 'Comparison Table',
    PORTFOLIO_LIST_BOTTOM_SHEET = 'Portfolio List Bottom Sheet',
    ALL_SIGNALS_TAB = 'All Signals Tab',
    PORTFOLIO_TAB = 'Portfolio Tab',
    MODEL_LIVE_TRADES_SECTION = 'Model Live Trades Section',
    HEADER = 'Header',
    BOTTOM_FILTER_BAR = 'Bottom Filter Bar',
    ADDITIONAL_SIGNAL_FILTER_BOTTOM_SHEET = 'Additional Signal Filters Bottom Sheet',
    STOCK = 'stocks',
    MODELS = 'models',
    ONBOARDING_STEPPER = 'Onboarding Stepper'
}
