/* Node Modules */
import axios from 'axios'
import {GetServerSidePropsContext} from 'next'
import nookies from 'nookies'

/* Constants */
import {AUTH_TOKEN, TEMP_AUTH_TOKEN} from 'Constants/constants'

/* Utils */
import {isWeb} from 'Utils/platformUtils'
import {getAPIHostUrl} from 'Utils/urlUtils'

const api = axios.create({
    baseURL: getAPIHostUrl()
})

export const setDefaultHeaders = (apiInstance = api, token) => {
    apiInstance.defaults.headers.common.Authorization = `Bearer ${
        token as string
    }`
}

export const fetchAccessTokenAndSetHeaders = (
    context?: GetServerSidePropsContext
) => {
    if (isWeb()) {
        const cookies = context ? nookies.get(context) : nookies.get()
        const accessToken = cookies?.[AUTH_TOKEN] || cookies?.[TEMP_AUTH_TOKEN]
        api.interceptors.response.use(
            response => {
                return response
            },
            error => {
                if (error?.toJSON?.()?.message === 'Network Error') {
                    throw error
                } else {
                    const {data, status} = error.response
                    throw {...data, status}
                }
            }
        )
        if (accessToken) {
            setDefaultHeaders(api, accessToken)
        }
    }
}

export default api
