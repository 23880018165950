/* Types */
import {Model} from 'Types/model'
import {Broker, InvesApiSmallcasePunchOrderRespObj, Order} from './broker'

export enum ActionType {
    BUZZ = 'BUZZ',
    SPOT = 'SPOT',
    QUANT = 'QUANT',
    STOCK = 'STOCK',
    BROKER = 'BROKER'
}

export enum ModelActionState {
    DRAFT = 'DRAFT',
    PUBLISHED = 'PUBLISHED',
    SCHEDULED = 'SCHEDULED',
    INVALIDATED = 'INVALIDATED',
    NO_ACTION = 'NO_ACTION'
}

export interface Action {
    id: number
    uuid: string
    name: string
    state: ModelActionState
    supportsRebalancing?: boolean
    validTill: Date
    comments: string
    model: Model
    publishAt: Date | null
    createdAt: Date
    updatedAt: Date
    deletedAt: Date | null
    readOnly?: boolean
}

export enum LifeCycleState {
    INIT = 'INIT',
    ENTERED = 'ENTERED',
    SL_CREATE = 'SL_CREATE',
    SL_UPDATE = 'SL_UPDATE',
    SL_HIT = 'SL_HIT',
    TGT_CREATE = 'TGT_CREATE',
    TGT_UPDATE = 'TGT_UPDATE',
    TGT_HIT = 'TGT_HIT',
    EXIT_INIT = 'EXIT_INIT',
    EXIT = 'EXIT',
    INVALIDATED = 'INVALIDATED',
    QTY_UPDATE = 'QTY_UPDATE',
    SL_CANCEL = 'SL_CANCEL',
    CUSTOM_EXIT = 'CUSTOM_EXIT'
}

export enum PositionType {
    BUY = 'BUY',
    SELL = 'SELL'
}

export enum ProductType {
    MIS = 'MIS',
    CNC = 'CNC',
    NRML = 'NRML'
}

export enum OrderType {
    MARKET = 'MARKET',
    LIMIT = 'LIMIT',
    SL = 'SL',
    SLM = 'SLM'
}
export enum ValidityType {
    IOC = 'IOC',
    DAY = 'DAY'
}

export enum LifecycleExecutionStatus {
    NOT_EXECUTED = 'NOT_EXECUTED',
    PARTIAL = 'PARTIAL',
    COMPLETE = 'COMPLETE',
    PENDING_AMO = 'PENDING_AMO'
}

export interface LifeCycle {
    id: number
    uuid: string
    signalId: number
    state: LifeCycleState
    triggerPrice: number | null
    orderType: OrderType
    positionType: PositionType
    productType: ProductType
    validityType: ValidityType
    price: number | null
    exitPrice?: number | null
    quantity: number
    comments: string
    createdAt: Date
    updatedAt: Date
    signal_id: number
    instrument: Instrument
    signal: any
    applicable: boolean
    order?: {
        quantity: number
        quantityFulfilled: number
        averagePrice: number
    }
    customExitSlsUuid?: string
    customInvestMoreSlsUuid?: string
    executionStatus: LifecycleExecutionStatus
}

export interface ExtraOrders {
    broker?: Broker
    redirectUrl: string
    orders?: Order[] | InvesApiSmallcasePunchOrderRespObj[]
}

export enum RebalanceType {
    REBALANCE_HOLDER = 'REBALANCE_HOLDER',
    REBALANCE = 'REBALANCE'
}

interface ActionMetaInfo {
    type: RebalanceType
}

export interface ActionDetails extends Action {
    lifecycles?: LifeCycle[]
    userActionExecutionStatus?: LifecycleExecutionStatus
    userActionExecutionType?: UserActionExecutionType
    doesUserTradedInitSignal?: boolean
    doesUserTrackedInitSignal?: boolean
    isExecutionFromModelPage?: boolean
    userInitActionExecutionType?: UserActionExecutionType
    isAddMoreFunds?: boolean
    showExitIntentPopup?: boolean
    extraOrders?: ExtraOrders
    doesUserDidAMO?: boolean
    brokerUsed?: string
    isDiscretionaryExit?: boolean
    isPostSignalExit?: boolean
    isSingleStockAddMore?: boolean
    meta?: ActionMetaInfo
}

export interface TradeLog {
    modelId: string
    pnl: number
    enteredPrice: number
    exitedPrice: number
    enteredDatetime: Date
    exitedDatetime: Date
    positionType: string
    createdAt: Date
    instrument: Instrument
}

export enum SIGNAL_UPDATE_TYPE {
    SL_CREATE = 'SL_CREATE',
    STOP_LOSS = 'SL_UPDATE',
    TARGET = 'TGT_UPDATE',
    QUANTITY = 'QTY_UPDATE',
    INVALIDATED = 'INVALIDATED',
    ENTERED = 'ENTERED',
    EXIT = 'EXIT',
    INIT = 'INIT'
}

export interface Instrument {
    name: string
    isin: string
    listingDate: Date
    symbol: string
    exchange: string
    instrumentToken: number
    tradingSymbol: string
    lotSize: number
}

export interface QuantityInfo {
    tradingSymbol: string
    quantity: number
    lifecycleId: string
    noActionRequired?: boolean
    originalQuantity?: number
}

export interface RecommendedActionQuantityResponse {
    actionRecommendations: QuantityInfo[]
    preferredCapital: number
    preferredLots: number
    minCapitalRequired?: number
    currentCapitalInvestedInModel?: number
}

export enum ExecutionThroughEnum {
    MANUAL = 'MANUAL',
    SMALLCASE = 'SMALLCASE',
    INVESBROKER = 'INVESBROKER'
}

export enum UserActionExecutionType {
    TRADING = 'TRADING',
    TRACKING = 'TRACKING'
}

export interface CloseActionsPayload {
    actions: string[]
}

export enum ModelUserActionMapping {
    TRADING = 'TRADING',
    TRACKING = 'TRACKING',
    ACTIONABLES = 'ACTIONABLES',
    NOT_ACTED = 'NOT_ACTED',
    NO_SIGNAL = 'NO_SIGNAL'
}

export enum OrderStatus {
    COMPLETE = 'COMPLETE',
    TRIGGER_PENDING = 'TRIGGER_PENDING',
    ERROR = 'ERROR',
    PARTIAL = 'PARTIAL'
}

export enum PostOrderStatus {
    SUCCESSFUL = 'successful',
    FAILED = 'failed',
    PARTIAL = 'partial',
    SL_CANCEL = 'cancel_sl_pending',
    PARTIAL_SL_CANCEL = 'cancel_sl_pending_and_partial',
    NO_READ_ACCESS = 'no_read_access'
}

export interface LifeCyclesOrderDetailsValue {
    quantity: number
    price: number
    orderPlaced?: boolean
    error?: string
    filledQuantity?: number
    isTriggerPending?: boolean
}

export type LifeCyclesOrderDetailsInterface = Record<
    string,
    LifeCyclesOrderDetailsValue
>

export enum RebalancePositionType {
    BUY = 'BUY',
    SELL = 'SELL',
    UNCHANGED = 'UNCHANGED'
}
